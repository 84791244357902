import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import CheckroomIcon from '@mui/icons-material/Checkroom';

const BottomNav = ({ onChatClick, onClothingClick }) => {
    return (
        <BottomNavigation
            showLabels
            sx={{ position: 'fixed', bottom: 0, width: '100%' }}
        >
            <BottomNavigationAction
                label="Traducir"
                icon={<TranslateIcon />}
                onClick={onChatClick}
            />
            <BottomNavigationAction
                label="Customizar"
                icon={<CheckroomIcon />}
                onClick={onClothingClick}
            />
        </BottomNavigation>
    );
};

export default BottomNav;
