import React, { useState } from 'react';
import { TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './styles.css';
import MicIcon from "@mui/icons-material/Mic";

const Chat = ({ onTextUpdate, onClose, onMouseDown, onMouseUp  }) => {
    const [text, setText] = useState('');
    const [isMicPressed, setIsMicPressed] = useState(false);

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleButtonClick = () => {
        if (text.trim()) {
            const timestamp = new Date().getTime();
            onTextUpdate({ text, timestamp });
            setText('');
            if (onClose) {
                onClose();
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleButtonClick();
        }
    }

    const handleMouseDown = () => {
        setIsMicPressed(true);
        if (onMouseDown) onMouseDown();
    };

    const handleMouseUp = () => {
        setIsMicPressed(false);
        if (onMouseUp) onMouseUp();
    };

    const handleTouchStart = () => {
        setIsMicPressed(true);
        if (onMouseDown) onMouseDown();
    };

    const handleTouchEnd = () => {
        setIsMicPressed(false);
        if (onMouseUp) onMouseUp();
    };

    return (
        <div className='chat-container'>
            <div className='input-container'>
                <TextField
                    className="custom-text-field"
                    label="Escriba su frase"
                    variant="outlined"
                    value={text}
                    onChange={handleTextChange}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    margin="normal"
                />
                <IconButton
                    className="send-button"
                    onClick={handleButtonClick}
                    sx={{
                        backgroundColor: '#5cb7e5',
                        color: 'white',
                        padding: '0.5em',
                        '&:hover': {
                            backgroundColor: '#2eadea'
                        }
                    }}
                >
                    <SendIcon />
                </IconButton>
                <IconButton
                    className={`mic-icon ${isMicPressed ? 'mic-icon-pressed' : ''}`}
                    sx={{
                        backgroundColor: '#5cb7e5',
                        color: 'white',
                        padding: '0.5em',
                        transition: 'transform 0.2s ease',
                        '&:hover': {
                            backgroundColor: '#2eadea'
                        }
                    }}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                >
                    <MicIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default Chat;