const animationsMap = {
    hola: "/animations/hola.fbx",
    aca: "/animations/aca.fbx",
    carnet: "/animations/carnet.fbx",
    gracias: "/animations/gracias.fbx",
    perdon: "/animations/perdon.fbx",
    como_estas: "/animations/como-estas.fbx",
    donde: "/animations/donde.fbx",
    nota: "/animations/nota.fbx",
    idle: "/animations/Idle.fbx",
    foto: "/animations/foto.fbx",
    pagar: "/animations/pagar.fbx",
    tarjeta: "/animations/tarjeta.fbx",
    documento: "/animations/documento.fbx",
    robo: "/animations/robo.fbx",
    sacar: "/animations/sacar.fbx",
    seguro: "/animations/seguro.fbx",
    tengo: "/animations/tengo.fbx",
    traer: "/animations/traer.fbx",
    turno: "/animations/turno.fbx",
    auto: "/animations/auto.fbx",
    conducir: "/animations/conducir.fbx",
    denuncia: "/animations/denuncia.fbx",
    examen: "/animations/examen.fbx",
    hoy: "/animations/hoy.fbx",
    medico: "/animations/medico.fbx",
    nuevo: "/animations/nuevo.fbx",
    papeles: "/animations/papeles.fbx",
    puede: "/animations/puede.fbx",
    quiero: "/animations/quiero.fbx",
    renovar: "/animations/renovar.fbx",
    asado: "/animations/asado.fbx",
    atencion: "/animations/atencion.fbx",
    atletismo: "/animations/atletismo.fbx",
    automovilismo: "/animations/automovilismo.fbx",
    ayer: "/animations/ayer.fbx",
    azucar: "/animations/azucar.fbx",
    azul: "/animations/azul.fbx",
    barrio: "/animations/barrio.fbx",
    basquet: "/animations/basquet.fbx",
    bebe: "/animations/bebe.fbx",
    blanco: "/animations/blanco.fbx",
    brasil: "/animations/brasil.fbx",
    brillante: "/animations/brillante.fbx",
    buenos_aires: "/animations/buenos-aires.fbx",
    carcel: "/animations/carcel.fbx",
    abril: "/animations/abril.fbx",
    amargo: "/animations/amargo.fbx",
    argentina: "/animations/argentina.fbx",
    arroz: "/animations/arroz.fbx",
    abuela: "/animations/abuela.fbx",
    abuelo: "/animations/abuelo.fbx",
    brillo: "/animations/brillo.fbx",
    cafe: "/animations/cafe.fbx",
    canada: "/animations/canada.fbx",
    agosto: "/animations/agosto.fbx",
    agua_saborizada: "/animations/agua-saborizada.fbx",
    agua: "/animations/agua.fbx",
    almacen: "/animations/almacen.fbx",
    amarillo: "/animations/amarillo.fbx",
    amigo: "/animations/amigo.fbx",
    anual: "/animations/anual.fbx",
    apellido: "/animations/apellido.fbx",
    años: "/animations/años.fbx",
    abogado: "/animations/abogado.fbx",
    aceite: "/animations/aceite.fbx",
    marzo: "/animations/marzo.fbx",
    mayo: "/animations/mayo.fbx",
    junio: "/animations/junio.fbx",
    julio: "/animations/julio.fbx",
    septiembre: "/animations/septiembre.fbx",
    diciembre: "/animations/diciembre.fbx",
    negro: "/animations/negro.fbx",
    oscuro: "/animations/oscuro.fbx",
    rojo: "/animations/rojo.fbx",
    rosa: "/animations/rosa.fbx",
    verde: "/animations/verde.fbx",
    violeta: "/animations/violeta.fbx",
    celeste: "/animations/celeste.fbx",
    claro: "/animations/claro.fbx",
    colores: "/animations/colores.fbx",
    gris: "/animations/gris.fbx",
    marron: "/animations/marron.fbx",
    naranja: "/animations/naranja.fbx",
    hockey: "/animations/hockey.fbx",
    handball: "/animations/handball.fbx",
    futbol: "/animations/futbol.fbx",
    golf: "/animations/golf.fbx",
    deportes: "/animations/deportes.fbx",
    ciclismo: "/animations/ciclismo.fbx",
    natacion: "/animations/natacion.fbx",
    padel: "/animations/padel.fbx",
    patin: "/animations/patin.fbx",
    rugby: "/animations/rugby.fbx",
    surf: "/animations/surf.fbx",
    tenis: "/animations/tenis.fbx",
    voley: "/animations/voley.fbx",
    cebolla: "/animations/cebolla.fbx",
    cerveza: "/animations/cerveza.fbx",
    chicle: "/animations/chicle.fbx",
    comida: "/animations/comida.fbx",
    comprar: "/animations/comprar.fbx",
    detergente: "/animations/detergente.fbx",
    dulce_de_leche: "/animations/dulce-de-leche.fbx",
    efectivo: "/animations/efectivo.fbx",
    esponja: "/animations/esponja.fbx",
    frio: "/animations/frio.fbx",
    hambre: "/animations/hambre.fbx",
    hay: "/animations/hay.fbx",
    huevo: "/animations/huevo.fbx",
    jamon: "/animations/jamon.fbx",
    leche: "/animations/leche.fbx",
    coca_cola: "/animations/coca-cola.fbx",
    cuanto_sale: "/animations/cuanto-sale.fbx",
    dia: "/animations/dia.fbx",
    edulcorante: "/animations/edulcorante.fbx",
    fideos: "/animations/fideos.fbx",
    manteca: "/animations/manteca.fbx",
    mañana: "/animations/mañana.fbx",
    mate: "/animations/mate.fbx",
    mayonesa: "/animations/mayonesa.fbx",
    mermelada: "/animations/mermelada.fbx",
    mes: "/animations/mes.fbx",
    papel_higienico: "/animations/papel-higienico.fbx",
    queso_untable: "/animations/queso-untable.fbx",
    queso: "/animations/queso.fbx",
    seven_up: "/animations/seven-up.fbx",
    yogur: "/animations/yogur.fbx",
    zanahoria: "/animations/zanahoria.fbx",
    vino: "/animations/vino.fbx",
    no_hay: "/animations/no-hay.fbx",
    pan: "/animations/pan.fbx",
    papas: "/animations/papas.fbx",
    pizza: "/animations/pizza.fbx",
    precio: "/animations/precio.fbx",
    sal: "/animations/sal.fbx",
    semana_que_viene: "/animations/semana-que-viene.fbx",
    servilleta: "/animations/servilleta.fbx",
    soda: "/animations/soda.fbx",
    tarta: "/animations/tarta.fbx",
    tomate: "/animations/tomate.fbx",
    casado: "/animations/casado.fbx",
    cumpleaños: "/animations/cumpleaños.fbx",
    cuñada: "/animations/cuñada.fbx",
    cuñado: "/animations/cuñado.fbx",
    direccion: "/animations/direccion.fbx",
    discapacidad: "/animations/discapacidad.fbx",
    edad: "/animations/edad.fbx",
    esposa: "/animations/esposa.fbx",
    esposo: "/animations/esposo.fbx",
    hijo: "/animations/hijo.fbx",
    hija: "/animations/hija.fbx",
    hijos: "/animations/hijos.fbx",
    hombre: "/animations/hombre.fbx",
    mama: "/animations/mama.fbx",
    papa: "/animations/papa.fbx",
    matrimonio: "/animations/matrimonio.fbx",
    mujer: "/animations/mujer.fbx",
    nieto: "/animations/nieto.fbx",
    harina: "/animations/harina.fbx",
    lechuga: "/animations/lechuga.fbx",
    limon: "/animations/limon.fbx",
    vinagre: "/animations/vinagre.fbx",
    familia: "/animations/familia.fbx",
    prima: "/animations/prima.fbx",
    primo: "/animations/primo.fbx",
    separado: "/animations/separado.fbx",
    sobrino: "/animations/sobrino.fbx",
    soltero: "/animations/soltero.fbx",
    sordo: "/animations/sordo.fbx",
    tia: "/animations/tia.fbx",
    tio: "/animations/tio.fbx",
    viudo: "/animations/viudo.fbx",
    aceptar: "/animations/aceptar.fbx",
    aprender: "/animations/aprender.fbx",
    armar: "/animations/armar.fbx",
    aterrizar: "/animations/aterrizar.fbx",
    ayudar: "/animations/ayudar.fbx",
    bailar: "/animations/bailar.fbx",
    bañar: "/animations/bañar.fbx",
    barrer: "/animations/barrer.fbx",
    burlar: "/animations/burlar.fbx",
    caminar: "/animations/caminar.fbx",
    capturar: "/animations/capturar.fbx",
    cocinar: "/animations/cocinar.fbx",
    contar: "/animations/contar.fbx",
    copiar: "/animations/copiar.fbx",
    dar: "/animations/dar.fbx",
    desarmar: "/animations/desarmar.fbx",
    chile: "/animations/chile.fbx",
    colombia: "/animations/colombia.fbx",
    costa_rica: "/animations/costa-rica.fbx",
    cuba: "/animations/cuba.fbx",
    ecuador: "/animations/ecuador.fbx",
    mexico: "/animations/mexico.fbx",
    pais: "/animations/pais.fbx",
    paises: "/animations/paises.fbx",
    panama: "/animations/panama.fbx",
    paraguay: "/animations/paraguay.fbx",
    peru: "/animations/peru.fbx",
    venezuela: "/animations/venezuela.fbx",
    a:"/animations/alphabet/a.fbx",
    b:"/animations/alphabet/b.fbx",
    c:"/animations/alphabet/c.fbx",
    d:"/animations/alphabet/d.fbx",
    e:"/animations/alphabet/e.fbx",
    f:"/animations/alphabet/f.fbx",
    g:"/animations/alphabet/g.fbx",
    h:"/animations/alphabet/h.fbx",
    i:"/animations/alphabet/i.fbx",
    j:"/animations/alphabet/j.fbx",
    k:"/animations/alphabet/k.fbx",
    l:"/animations/alphabet/l.fbx",
    m:"/animations/alphabet/m.fbx",
    n:"/animations/alphabet/n.fbx",
    o:"/animations/alphabet/o.fbx",
    p:"/animations/alphabet/p.fbx",
    q:"/animations/alphabet/q.fbx",
    r:"/animations/alphabet/r.fbx",
    s:"/animations/alphabet/s.fbx",
    t:"/animations/alphabet/t.fbx",
    u:"/animations/alphabet/u.fbx",
    v:"/animations/alphabet/v.fbx",
    w:"/animations/alphabet/w.fbx",
    x:"/animations/alphabet/x.fbx",
    y:"/animations/alphabet/y.fbx",
    z:"/animations/alphabet/z.fbx",
    ñ:"/animations/alphabet/ñ.fbx",
};

export default animationsMap;