import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';

function Hoodie() {
    const group = useRef<THREE.Group>(null);
    const { nodes, materials } = useGLTF('/models/DilloAvatar-v4.glb');

    return (
        <group dispose={null} ref={group}>
            <group name="Scene">
                <group name="Ready4Retarget(MAKE_A_COPY)001">
                    <primitive object={nodes.Hips} />
                    <primitive object={nodes.Ctrl_Master} />
                    <primitive object={nodes.Ctrl_ArmPole_IK_Left} />
                    <primitive object={nodes.Ctrl_Hand_IK_Left} />
                    <primitive object={nodes.Ctrl_ArmPole_IK_Right} />
                    <primitive object={nodes.Ctrl_Hand_IK_Right} />
                    <primitive object={nodes.Ctrl_Foot_IK_Left} />
                    <primitive object={nodes.Ctrl_LegPole_IK_Left} />
                    <primitive object={nodes.Ctrl_Foot_IK_Right} />
                    <primitive object={nodes.Ctrl_LegPole_IK_Right} />
                    <primitive object={nodes.root} />
                    <skinnedMesh name="Wolf3D_Body002" geometry={(nodes.Wolf3D_Body002 as THREE.Mesh).geometry} material={materials.Wolf3D_Body} skeleton={(nodes.Wolf3D_Body002 as THREE.SkinnedMesh).skeleton} />
                    <skinnedMesh name="Wolf3D_Hair002" geometry={(nodes.Wolf3D_Hair002 as THREE.Mesh).geometry} material={materials.Wolf3D_Hair} skeleton={(nodes.Wolf3D_Hair002 as THREE.SkinnedMesh).skeleton} />
                    <skinnedMesh name="Wolf3D_Outfit_Bottom002" geometry={(nodes.Wolf3D_Outfit_Bottom002 as THREE.Mesh).geometry} material={materials.Wolf3D_Outfit_Bottom} skeleton={(nodes.Wolf3D_Outfit_Bottom002 as THREE.SkinnedMesh).skeleton} />
                    <skinnedMesh name="Wolf3D_Outfit_Footwear002" geometry={(nodes.Wolf3D_Outfit_Footwear002 as THREE.Mesh).geometry} material={materials.Wolf3D_Outfit_Footwear} skeleton={(nodes.Wolf3D_Outfit_Footwear002 as THREE.SkinnedMesh).skeleton} />
                    <skinnedMesh name="Wolf3D_Outfit_Top002" geometry={(nodes.Wolf3D_Outfit_Top002 as THREE.Mesh).geometry} material={materials.Wolf3D_Outfit_Top} skeleton={(nodes.Wolf3D_Outfit_Top002 as THREE.SkinnedMesh).skeleton} />
                    <skinnedMesh name="Wolf3D_Head002" geometry={(nodes.Wolf3D_Head002 as THREE.Mesh).geometry} material={materials.Wolf3D_Skin} skeleton={(nodes.Wolf3D_Head002 as THREE.SkinnedMesh).skeleton} morphTargetDictionary={(nodes.Wolf3D_Head002 as THREE.SkinnedMesh).morphTargetDictionary} morphTargetInfluences={(nodes.Wolf3D_Head002 as THREE.SkinnedMesh).morphTargetInfluences} />
                    <skinnedMesh name="EyeLeft002" geometry={(nodes.EyeLeft002 as THREE.Mesh).geometry} material={materials.Wolf3D_Eye} skeleton={(nodes.EyeLeft002 as THREE.SkinnedMesh).skeleton} morphTargetDictionary={(nodes.EyeLeft002 as THREE.SkinnedMesh).morphTargetDictionary} morphTargetInfluences={(nodes.EyeLeft002 as THREE.SkinnedMesh).morphTargetInfluences} />
                    <skinnedMesh name="EyeRight002" geometry={(nodes.EyeRight002 as THREE.Mesh).geometry} material={materials.Wolf3D_Eye} skeleton={(nodes.EyeRight002 as THREE.SkinnedMesh).skeleton} morphTargetDictionary={(nodes.EyeRight002 as THREE.SkinnedMesh).morphTargetDictionary} morphTargetInfluences={(nodes.EyeRight002 as THREE.SkinnedMesh).morphTargetInfluences} />
                    <skinnedMesh name="Wolf3D_Teeth002" geometry={(nodes.Wolf3D_Teeth002 as THREE.Mesh).geometry} material={materials.Wolf3D_Teeth} skeleton={(nodes.Wolf3D_Teeth002 as THREE.SkinnedMesh).skeleton} morphTargetDictionary={(nodes.Wolf3D_Teeth002 as THREE.SkinnedMesh).morphTargetDictionary} morphTargetInfluences={(nodes.Wolf3D_Teeth002 as THREE.SkinnedMesh).morphTargetInfluences} />
                </group>
            </group>
        </group>
    );
}

export default Hoodie;
