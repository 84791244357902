import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';

function Tee() {
    const group = useRef<THREE.Group>(null);
    const { nodes, materials } = useGLTF('/models/DilloAvatar-Tee.glb');

    return (
        <group ref={group} dispose={null}>
          <group name="Scene">
            <group name="Armature001">
              <primitive object={nodes.Hips} />
              <primitive object={nodes.Ctrl_Master} />
              <primitive object={nodes.Ctrl_ArmPole_IK_Left} />
              <primitive object={nodes.Ctrl_Hand_IK_Left} />
              <primitive object={nodes.Ctrl_ArmPole_IK_Right} />
              <primitive object={nodes.Ctrl_Hand_IK_Right} />
              <primitive object={nodes.Ctrl_Foot_IK_Left} />
              <primitive object={nodes.Ctrl_LegPole_IK_Left} />
              <primitive object={nodes.Ctrl_Foot_IK_Right} />
              <primitive object={nodes.Ctrl_LegPole_IK_Right} />
              <primitive object={nodes.root} />
              <skinnedMesh name="Wolf3D_Body" geometry={(nodes.Wolf3D_Body as THREE.Mesh).geometry} material={materials['Wolf3D_Body.001']} skeleton={(nodes.Wolf3D_Body as THREE.SkinnedMesh).skeleton} />
              <skinnedMesh name="Wolf3D_Hair" geometry={(nodes.Wolf3D_Hair as THREE.Mesh).geometry} material={materials['Wolf3D_Hair.001']} skeleton={(nodes.Wolf3D_Hair as THREE.SkinnedMesh).skeleton} />
              <skinnedMesh name="Wolf3D_Outfit_Bottom" geometry={(nodes.Wolf3D_Outfit_Bottom as THREE.Mesh).geometry} material={materials['Wolf3D_Outfit_Bottom.001']} skeleton={(nodes.Wolf3D_Outfit_Bottom as THREE.SkinnedMesh).skeleton} />
              <skinnedMesh name="Wolf3D_Outfit_Top" geometry={(nodes.Wolf3D_Outfit_Top as THREE.Mesh).geometry} material={materials['Wolf3D_Outfit_Top.001']} skeleton={(nodes.Wolf3D_Outfit_Top as THREE.SkinnedMesh).skeleton} />
              <skinnedMesh name="EyeLeft" geometry={(nodes.EyeLeft as THREE.Mesh).geometry} material={materials['Wolf3D_Eye.001']} skeleton={(nodes.EyeLeft as THREE.SkinnedMesh).skeleton} morphTargetDictionary={(nodes.EyeLeft as THREE.SkinnedMesh).morphTargetDictionary} morphTargetInfluences={(nodes.EyeLeft as THREE.SkinnedMesh).morphTargetInfluences} />
              <skinnedMesh name="EyeRight" geometry={(nodes.EyeRight as THREE.Mesh).geometry} material={materials['Wolf3D_Eye.001']} skeleton={(nodes.EyeRight as THREE.SkinnedMesh).skeleton} morphTargetDictionary={(nodes.EyeRight as THREE.SkinnedMesh).morphTargetDictionary} morphTargetInfluences={(nodes.EyeRight as THREE.SkinnedMesh).morphTargetInfluences} />
              <skinnedMesh name="Wolf3D_Head" geometry={(nodes.Wolf3D_Head as THREE.Mesh).geometry} material={materials['Wolf3D_Skin.001']} skeleton={(nodes.Wolf3D_Head as THREE.SkinnedMesh).skeleton} morphTargetDictionary={(nodes.Wolf3D_Head as THREE.SkinnedMesh).morphTargetDictionary} morphTargetInfluences={(nodes.Wolf3D_Head as THREE.SkinnedMesh).morphTargetInfluences} />
              <skinnedMesh name="Wolf3D_Teeth" geometry={(nodes.Wolf3D_Teeth as THREE.Mesh).geometry} material={materials['Wolf3D_Teeth.001']} skeleton={(nodes.Wolf3D_Teeth as THREE.SkinnedMesh).skeleton} morphTargetDictionary={(nodes.Wolf3D_Teeth as THREE.SkinnedMesh).morphTargetDictionary} morphTargetInfluences={(nodes.Wolf3D_Teeth as THREE.SkinnedMesh).morphTargetInfluences} />
            </group>
          </group>
        </group>
      )
    }

export default Tee;