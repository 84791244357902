import React, { useState } from 'react';
import './styles.css';
import PocketBase from 'pocketbase';
import { Divider } from '@mui/material';
import useDeviceType from '../../utils/useDeviceType';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

const ProfileModal = () => {
    const deviceType = useDeviceType()
    const navigate = useNavigate()
    const [isOpenModal, setIsOpenModal] = useState(false)
    const pb = new PocketBase('https://auth.dillo.ar/');

    const LocalStorageData = JSON.parse(localStorage.getItem('pocketbase_auth'))
    const accountName = LocalStorageData?.record?.email

    const handleOnSingOut = () => {
        pb.authStore.clear();
        navigate("/ingresar")
    }

    const handleNavigate = (path) => {
        setIsOpenModal(false);
        navigate(path);
    };

    if(!isOpenModal){
        return (
            <div className="open-modal-button-container">
                <button onClick={() => setIsOpenModal(true)}>
                    <img src={`/menu.svg`} alt='Menu de usuario'/>
                </button>
            </div>
        )
    }

    return (
        <div onClick={() => setIsOpenModal(false)} className="modal-container">
            <div onClick={(e) => e.stopPropagation()} className={`${deviceType !== 'mobile' ? 'modal-content-desktop' : 'modal-content'}`}>
                <div className="header-modal-container">
                    <button onClick={() => setIsOpenModal(false)}> <CloseIcon /> </button>
                </div>
                <div className='profile-container'>
                    <div className='profile-image-container'>
                        <AccountCircleOutlinedIcon className="account-icon" style={{ fontSize: 48 }} />
                    </div>
                    <div className="profile-welcome">
                        <span className="welcome-text">Bienvenido</span>
                        <span className="account-name-text"> {accountName}</span>
                    </div>
                </div>
                <div className='profile-divider'>
                    <Divider />
                </div>
                <button className="modal-element" onClick={() => handleNavigate('/')}>
                    <SignLanguageIcon className='menu-icon' /> Avatar
                </button>
                <Divider />
                <Divider />
                <a className="modal-element" href="https://dillo.ai/#contacto" target="_blank" rel="noopener noreferrer">
                    <EmailOutlinedIcon className='menu-icon' /> Contacto
                </a>
                <Divider />
                <button className="modal-element" onClick={() => handleOnSingOut()}>
                    <LogoutIcon className='menu-icon' /> Cerrar sesión
                </button>
                <Divider />
                <div className="social-media-buttons">
                    <a href="https://www.linkedin.com/company/dilloai/" target="_blank" rel="noopener noreferrer" className="social-media-icon">
                        <LinkedInIcon style={{ fontSize: 36 }} />
                    </a>
                    <a href="https://www.instagram.com/dillo.ai/" target="_blank" rel="noopener noreferrer" className="social-media-icon">
                        <InstagramIcon  style={{ fontSize: 36 }} />
                    </a>

                    <a href="https://www.facebook.com/people/Dilloai/61556508354118/" target="_blank" rel="noopener noreferrer" className="social-media-icon">
                        <FacebookIcon style={{ fontSize: 36 }} />
                    </a>
                </div>
            </div>
        </div>
    );
};


export default ProfileModal;