import React from 'react';
import { IconButton } from '@mui/material';
import './styles.css';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import useDeviceType from '../../utils/useDeviceType';

const ReplayButton = ({ onClick }) => {
    const deviceType = useDeviceType();

    const bottomPosition = deviceType === 'mobile' ? '5em' : '3.8em';
    const rightPosition = deviceType === 'mobile' ? '1em' : '2em';

    return (
        <div
            className='reload-button'
            style={{ bottom: bottomPosition, right: rightPosition }}
        >
            <IconButton
                onClick={onClick}
                className='reload-icon-button'
                sx={{
                    backgroundColor: '#ffffff',
                    padding: '0.2em',
                    borderRadius: '0.3em',
                    '&:hover': {
                        backgroundColor: 'rgb(237,244,251)'
                    }
                }}
            >
                <AutorenewIcon  />
            </IconButton>
        </div>
    );
};

export default ReplayButton;