import React from 'react';
import AnimatedModel from "./AnimatedModel.tsx";
import { playAudio } from "../api/api.ts";

export function Model({ clothing, text, speed }) {
    // useEffect(() => {
    //     async function playAvatarAudio() {
    //         await playAudio("hola como_estas donde sacar carnet conducir tengo turno hoy");
    //     }
    //     playAvatarAudio();
    // }, []);

    return (
        <AnimatedModel clothing={clothing} word={text} speed={speed} />
  );
}
