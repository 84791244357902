import React, { memo } from 'react';
import { Environment, OrbitControls } from "@react-three/drei";
import { Model } from "./Avatar.tsx";

const ExperienceComponent = ({ clothing, text, speed }) => {

    return (
        <>
            <OrbitControls enabled={false} />
            <Model clothing={clothing} text={text} speed={speed} />
            <Environment preset="sunset" />
        </>
    );
};

export const Experience = memo(ExperienceComponent);