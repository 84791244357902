import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import './styles.css';
import useDeviceType from "../../utils/useDeviceType.ts";

const SpeedButton = ({ onSpeedChange }) => {
    const [speed, setSpeed] = useState(1);
    const deviceType = useDeviceType();

    const bottomPosition = deviceType === 'mobile' ? '5em' : '3.8em';
    const rightPosition = deviceType === 'mobile' ? '' : '5.4em';
    const leftPosition = deviceType === 'mobile' ? '1em' : '';

    const handleClick = () => {
        const newSpeed = speed === 1.5 ? 0.5 : speed + 0.5;
        setSpeed(newSpeed);
        onSpeedChange(newSpeed);
    };

    return (
        <div className='speed-button'
             style={{ bottom: bottomPosition, right: rightPosition, left: leftPosition }}
        >
            <IconButton
                onClick={handleClick}
                className='speed-icon-button'
                sx={{
                    backgroundColor: '#ffffff',
                    padding: '0.2em',
                    borderRadius: '0.3em',
                    '&:hover': {
                        backgroundColor: 'rgb(237,244,251)'
                    }
                }}
            >
                {speed}
            </IconButton>
        </div>
    );
};

export default SpeedButton;
