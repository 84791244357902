import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useFBX, useAnimations } from '@react-three/drei';
import * as THREE from 'three';
import animationsMap from "./AnimationsMap.tsx";
import Hoodie from "./Hoodie";
import Sweater from "./Sweater";
import Shirt from './Shirt';
import Tee from './Tee';
import {processString} from "../utils/processString";


function AnimatedModel({ clothing, word, speed }) {
    const filterAnimationsMap = useMemo(() => (word: string, animations: { [x: string]: any; }) => {

        const words = processString(word)

        const filteredMap = {};

        words.forEach((word: string) => {
            if (animations[word]) {
                filteredMap[word] = animations[word];
            }else{
                const wordSet = new Set(word.split(''));

                for (const key in animations) {
                    if (wordSet.has(key)) {
                        filteredMap[key] = animations[key];
                    }
                }
            }
        });

        if (animations[word]) {
            filteredMap[word] = animations[word];
            filteredMap['idle'] = animations['idle'];
            return filteredMap;
        }

        if (animations['idle']) {
            filteredMap['idle'] = animations['idle'];
        }

        return filteredMap;
    }, []);

    const result = filterAnimationsMap(word, animationsMap);

    const loadedAnimations = useMemo(() =>
        Object.entries(result).reduce((acc: Record<string, THREE.AnimationClip>, [key, path]) => {
            const { animations } = useFBX(path as string);
            if (animations.length > 0) {
                animations[0].name = key;
                acc[key] = animations[0];
            }
            return acc;
        }, {}), [result]);

    const [animation, setAnimation] = useState(word);
    const group = useRef<THREE.Group>(null);
    const { actions, mixer } = useAnimations(Object.values(loadedAnimations) as THREE.AnimationClip[], group);

    useEffect(() => {
        if (!actions || !word || !animation) return;

        const playPhraseAnimations = async () => {
            let lastAction = null;

            const words = processString(word)

            for (const part of words) {

                if (actions[part]) {
                    const action = actions[part];

                    action.clampWhenFinished = true;
                    action.timeScale = 1.8 * speed;
                    if (lastAction){
                        action.reset().setLoop(THREE.LoopOnce, 1).crossFadeFrom(lastAction, 0.5, false).play();
                    }else{
                        action.reset().setLoop(THREE.LoopOnce, 1).play();
                    }
                    await new Promise(resolve => setTimeout(resolve, 1800 / speed ));
                    action.fadeOut(0.5);
                    lastAction = action;
                } else {
                    lastAction = await playWordSpellingAnimations(part);
                }
            }

            return lastAction;
        };

        const playIdleAnimation = () => {
            if (actions.idle) {
                // @ts-ignore
                actions.idle.reset().setLoop(THREE.LoopRepeat).fadeIn(0.5).play();
            }
        };

        const playWordSpellingAnimations = async (spelling) => {
            let lastAction = null;
            for (const char of spelling) {
                const action = actions[char];
                if (action) {
                    action.clampWhenFinished = true;
                    action.timeScale = 1.5  * speed;
                    if (lastAction){
                        action.reset().setLoop(THREE.LoopOnce, 1).crossFadeFrom(lastAction, 0.5, false).play();
                    }else{
                        action.reset().setLoop(THREE.LoopOnce, 1).play();
                    }
                    await new Promise(resolve => setTimeout(resolve, 1500 / speed ));
                    action.fadeOut(0.5);
                    lastAction = action;
                }
            }
            return lastAction;
        };

        let lastPlayedAction = null;
        const playAnimation = async () => {

            if (lastPlayedAction == null)
            lastPlayedAction = await playPhraseAnimations();
            if (lastPlayedAction && actions.idle) {
                // @ts-ignore
                actions.idle.reset().setLoop(THREE.LoopRepeat).crossFadeFrom(lastPlayedAction, 0.5, false).play();
            } else {
                playIdleAnimation();
            }
        };

        playAnimation();
    }, [animation, actions, mixer, word, speed]);

    const getModelComponent = () => {
        switch (clothing) {
            case 'hoodie':
                return <Hoodie />;
            case 'sweater':
                return <Sweater />;
            case 'shirt':
                return <Shirt />;
            case 'tee':
                return <Tee />;
            default:
                return null;
        }
    };

    return (
        <>
            <group ref={group} position={[0, -2.8, 3]} scale={[2, 2, 2]}>
                {getModelComponent()}
            </group>
        </>
    );
}

export default AnimatedModel;
